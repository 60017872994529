import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import secureStorage from '../../_helpers/secureStorage';
import get from 'lodash/get';
import './styles.css';
import { connect } from 'react-redux';
import { memberActions, newMemberActions } from '../../_actions';
import { logGTMEvent } from '../../_helpers';
import { useRenameDocument } from '../../context/renameDocument';

const Login = ({
  dispatch,
  email,
  isAdmin,
  loggingIn,
  loggedIn,
  error,
  signinError,
  shouldResetPassword,
  hasResetPassword,
  history,
}) => {
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [err, setError] = useState('');

  useRenameDocument('Sign In');

  /*eslint-disable */
  useEffect(() => {
    setError(signinError);
    dispatch(newMemberActions.clearNewMember());
    dispatch(newMemberActions.clearPaymentForm());
    dispatch(memberActions.updateSupplierInfo({}));
  }, []);
  /*eslint-enable */

  const postLogin = event => {
    const form = document.forms[0];
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const test = new secureStorage();
      // if localstorage is available then initiate login
      if (Object.keys(test).includes('ls')) {
        dispatch(memberActions.login(email, password));
      } else {
        // otherwise show message
        history.push('/Private');
      }
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      postLogin(e);
    }
  };

  if (isAdmin) {
    return <Redirect to="/Admin" />;
  }

  return loggedIn ? (
    <Redirect to="/main" />
  ) : shouldResetPassword && !hasResetPassword ? (
    <Redirect to="/ResetRequired" />
  ) : (
    <div className="container" aria-live='polite'>
      <div
        id="mui-column"
        className="col-xs-12 col-sm-10 col-lg-6 offset-sm-1 offset-lg-3 p-2 p-sm-5 rounded bg-light">
        <Form noValidate validated={validated}>
          <input type="hidden" />
          <p className="text-left secondary-cta">
            <Link to="/CreateNewSignin">
              To create an online login for wine club account,{' '}
              <span className="cta-highlight">Create Login</span>
            </Link>
            .
          </p>
          <h2 className="log-title">Sign In</h2>
          <h6 className="log-title mb-sm-4">
            with your Wine Club Member Account
          </h6>
          <Form.Group aria-live='polite'>
            <Form.Label
              className="input-label"
              style={{ color: 'white' }}
              for="email">
              Email
            </Form.Label>
            <Form.Control
              value={email}
              onChange={e => dispatch(memberActions.setEmail(e.target.value))}
              autoFocus="autofocus"
              id="email"
              aria-label="Email"
              placeholder="Email"
              required="required"
              type="email"
              isInvalid={error === 'UserDoesNotExist'}
              aria-errormessage='errorMsg'
            />
            <Form.Control.Feedback type='invalid' id='errorMsg' aria-invalid='true'>
              Invalid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group aria-live='assertive'>
            <Form.Label
              className="input-label"
              style={{ color: 'white' }}
              for="password">
              Password
            </Form.Label>
            <Form.Control
              value={password}
              onInput={e => setPassword(e.target.value)}
              id="password"
              aria-label="Password"
              placeholder="Password"
              required="required"
              type="password"
              isInvalid={error === 'InvalidLogin'}
              aria-errormessage='psswdErrorMsg'
              onKeyDown={handleKeyPress}
            />
            <Form.Control.Feedback type="invalid" id='psswdErrorMsg' aria-invalid='true'>
              The password is incorrect. Enter a correct password and try again.
            </Form.Control.Feedback>
          </Form.Group>
          {err === 'no-contacts' && (
            <p className="login-error">
              Unable to log in. Please call member &amp; guest services for
              assistance at <strong>(708) 215-5674</strong>. Code: 1000
            </p>
          )}
          {err === 'inactive' && (
            <p className="login-error">
              This account has not been activated. Please call member &amp;
              guest services for assistance at <strong>(708) 215-5674</strong>.
            </p>
          )}
          <div className="list-group visible my-3" id="member_select" />
          <div className="log-line clearfix">
            <div className="float-left">
              <Button
                disabled={loggingIn}
                className="btn btn-primary"
                onClick={e => (!loggingIn ? postLogin(e) : null)}
                type="button">
                {loggingIn ? 'Logging in…' : 'Sign In'}
              </Button>
              <div className="clearfix" />
            </div>
            <div className="float-right">
              <Link className="forgot-password" to="/ForgotPassword">
                Forgot your Password?
              </Link>
            </div>
          </div>
          <br />
          <Form.Group className="mt-3">
            <p className="text-left secondary-cta">
              <Link
                to="/Signup"
                onClick={() =>
                  logGTMEvent('wine_club_join_click', {
                    page_title: 'Sign In',
                    page_path: '/SignIn',
                  })
                }>
                Want to become a Wine Club Member?{' '}
                <span className="cta-highlight">Sign Up</span>
              </Link>
              .
            </p>
            <br />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    loggingIn,
    loggedIn,
    shouldResetPassword,
    hasResetPassword,
    error,
    signinError,
  } = state.authentication;
  const { member } = state;
  const isAdmin = get(member, 'isAdmin', false);
  const email = get(member, 'email', '');

  return {
    email,
    isAdmin,
    loggingIn,
    loggedIn,
    shouldResetPassword,
    hasResetPassword,
    error,
    signinError,
  };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };
