import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import "./styles.css";


const BenefitsColumn = ({ collapsible }) => {
const [collapsed, setCollapsed] = useState(true);

const showHide = () => {
    setCollapsed(!collapsed);
}

    return (
        <Col lg={6}>
            <div className="pt-4 pl-5 pr-5 claim-col">
                <div className="claim-banner">
                    <h3 className="claim-text">Claim Your 3-Month Supplier Club Membership</h3>
                </div>
                {(!collapsible || !collapsed) &&
                <div style={{ paddingTop: 32, paddingBottom: 16, }}>
                    <h5>Membership Benefits:</h5>
                    <ul className="text-libre">
                        <li>Variety Wine Tasting for two each month ($24 value)</li>
                        <li>$15 Welcome Reward</li>
                        <li>Earn 1 Point Per $1 Spent (on dining, retail wine and more)*</li>
                        <li>Retail Wine Discounts (10% on 1-5 bottles, 15% on 6-11 bottles, 20% on 12+bottles)*</li>
                        <li>10% Off Carryout and Catering</li>
                        <li>Visit <a href='https://chwinery.com/supplierclub'>Supplier Club Membership</a> for more information</li>
                    </ul>
                    <p className="text-libre">
                        * <i>For full membership details and benefits, please visit <a href='https://chwinery.com/supplierclub'> https://chwinery.com/supplierclub </a></i></p>
                </div>
                }
                {collapsible && <span className="pointer" onClick={showHide}><p className="show-hide">{collapsed ? 'Show all benefits' : 'Show less'}</p></span>}
            </div>
        </Col>
    );
};


export default BenefitsColumn;
