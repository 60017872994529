import React, { useState, useEffect, useRef } from "react";
import { Header, CholoInput, ChoLoader, Footer } from "../../components";
import { Button, Col, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ThemedBackArrow from "../../components/ThemedBackArrow";
import infoIcon from "../../assets/images/icon-info.png";
import get from "lodash/get";
import secureStorage from "../../_helpers/secureStorage";

import { Redirect } from "react-router-dom";

import { newMemberActions, memberActions, alertActions } from "../../_actions";

import "./styles.scoped.css";
import { appConstants } from "../../_constants";
import { useCarryoutRename } from "../../context/renameDocument";

const ProfileOlo = ({
  dispatch,
  history,
  email,
  loggingIn,
  loggedIn,
  loginDestination,
  firstName,
  lastName,
  phone,
  changingEmailBackend,
  profileObject,
  loading,
  membershipType,
}) => {
  const onBackToBag = () => {
    history.goBack();
  };

  useCarryoutRename("Profile");

  const emailPattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  const [phoneValid,setPhoneValid] = useState(true);
  const [changingEmail, setChangingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const maskPhone = (value) => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    return value;
  };

  const handleSubmit = (event) => {
    setShowInputError(true);
    setIsSubmitted(true);
    if (firstName != "" &&
      lastName != "" &&
      phoneValid
    ) {
      dispatch(memberActions.postProfile(profileObject));
      setProfileUpdated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const changeEmail = () => {
    var ls = new secureStorage();
    const user = JSON.parse(ls.get("user"));
    const contactId = get(user, "id", "");
    dispatch(memberActions.changeEmail(newEmail, email, contactId));
    setChangingEmail(false);
  };

  const [showInputError, setShowInputError] = useState(false);

  return (
    <>
      <div className="big-wrap">
        <Header onBagClick={onBackToBag} hideLogin />
        <Col className="little-wrap">
          <div className="back-to-my-bag" onClick={onBackToBag}>
            <ThemedBackArrow className="icon-back" width="20px"/>
            <p className="back-to-my-bag-text">Back to my bag</p>
          </div>
          <div className="col-xs-12 col-sm-10 col-lg-6">
            <h2 className="title">Account Details</h2>
            <div className="inner-wrap">
              {membershipType !== "Potential" && (
                <a
                  href={`${appConstants.memberUrl}/main#profile`}
                  target="_blank"
                >
                  <div className="title-smaller">View Wine Club Member Account</div>
                </a>
              )}

              <Form>
                <span className="input-title">First Name</span>
                <CholoInput
                  isValid={firstName !== ""}
                  isSubmitted={isSubmitted}
                  errorMessage="Please provide a valid first name."
                  placeholder="First Name"
                  value={firstName}
                  onTextChanged={(value) =>
                    dispatch(memberActions.updateFirstName(value))
                  }
                  autoComplete="off"
                />

                <span className="input-title">Last Name</span>
                <CholoInput
                  isValid={lastName != ""}
                  isSubmitted={isSubmitted}
                  errorMessage="Please provide a valid last name."
                  placeholder="Last Name"
                  value={lastName}
                  onTextChanged={(value) => {
                    dispatch(memberActions.updateLastName(value));
                  }}
                  autoComplete="off"
                />

                <div>
                  <span className="input-title">Phone Number</span>
                  <CholoInput
                    isValid={phone !== "" && phone?.replace(/\D/g, "").length === 10}
                    isSubmitted={isSubmitted}
                    errorMessage="Please enter area code and phone number, numbers only"
                    placeholder="Phone Number xxx-xxx-xxxx"
                    value={maskPhone(phone)}
                    onTextChanged={(value) => {
                      let changedPhone = value.replace(/\D/g, "").substr(0, 10);
                      dispatch(memberActions.updatePhone(changedPhone));
                      (changedPhone.length === 10) ? 
                        setPhoneValid(true):
                        setPhoneValid(false);
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    autoComplete="tel"
                    
                  />
                </div>
                <span className="input-title">
                  {changingEmail ? "Current Email" : "Email"}

                  <span
                    onClick={() => setChangingEmail(true)}
                    style={{
                      color: "var(--carryout-site-gold)",
                      fontSize: "0.9em",
                      textDecoration: "underline",
                      marginLeft: "5px",
                      cursor: "pointer"
                    }}
                  >
                    {changingEmail ? "" : "(update email)"}
                  </span>
                </span>
                <CholoInput
                  isValid={email}
                  errorMessage="Passwords must match"
                  placeholder="Email"
                  value={email}
                  onKeyPress={(e) => handleKeyPress(e)}
                  autoComplete="off"
                  disabled={true}
                />

                {changingEmail && (
                  <>
                    <span className="input-title">
                      New Email
                      <span
                        onClick={() => setChangingEmail(!changingEmail)}
                        style={{
                          color: "var(--carryout-site-gold)",
                          fontSize: "0.9em",
                          textDecoration: "underline",
                          marginLeft: "5px",
                          cursor: "pointer"
                        }}
                      >
                        {changingEmail ? " (cancel)" : " (change)"}
                      </span>
                    </span>

                    <CholoInput
                      errorMessage=" Please provide a valid email."
                      isValid={emailPattern.test(newEmail)}
                      value={newEmail}
                      name="newEmail"
                      onTextChanged={(value) => setNewEmail(value)}
                    />
                    {changingEmail && (
                      <Button
                        className="button pad"
                        disabled={
                          !emailPattern.test(newEmail) ||
                          changingEmailBackend ||
                          newEmail === email
                        }
                        onClick={() => changeEmail()}
                      >
                        {changingEmailBackend ? "CHANGING..." : "CHANGE EMAIL"}
                      </Button>
                    )}
                  </>
                )}
                {!loading && profileUpdated && (
                  <span className="input-title">
                    <i>Profile Updated</i>
                  </span>
                )}
              </Form>

              <Row>
                <Col xs={"auto"}>
                  <Button
                    className="button pad"
                    id="new_password"
                    onClick={(e) => handleSubmit(e)}
                  >
                    SAVE CHANGES
                  </Button>
                </Col>
                <Col xs={"auto"}>
                  <Link to="/forgotpassword-olo">
                    <Button className="button pad">UPDATE PASSWORD</Button>
                  </Link>
                </Col>
              </Row>
              <div className="divider" />
              <Row className="horizontal-edges">
                <Col>
                  <Row>
                    <div className="lefty dont-have">Questions?</div>
                    <div className="checkout-as-guest">
                      <a href="tel:7082155674">(708) 215-5674</a>
                    </div>
                  </Row>
                </Col>
                <Col />
              </Row>
            </div>
          </div>

          {loading && <ChoLoader />}
        </Col>
        <Footer />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn, error, signinError } = state.authentication;
  const { member, newMember } = state;
  const loading = get(member, "loading", false);
  const potentialEmail = get(newMember, "data.potentialEmail", "");
  const potentialResponse = get(newMember, "paymentUrl", "");
  const loginDestination = get(member, "loginDestination");
  const memberEmail = get(member, "data.email", "");
  const changingEmailBackend = get(member, "changingEmail", false);

  var ls = new secureStorage();
  const user = JSON.parse(ls.get("user"));
  const contactId = get(user, "id", "");

  const firstName = get(member, "data.firstName", "");
  const lastName = get(member, "data.lastName", "");
  const birthDate = get(member, "data.birthday", "");
  const membershipType = get(member, "data.membershipType", "");
  const clubType = get(member, "data.clubType", "");
  const bottleLevel = get(member, "data.bottleLevel", "");
  const deliveryType = get(member, "data.deliveryType", "");
  const phone = get(member, "data.phone", "") || "";
  const email = get(member, "data.email", "");
  const shippingAddress = get(member, "data.shippingAddress", {});
  const homeAddress = get(member, "data.homeAddress", {});
  const street = get(homeAddress, "street", "");
  const city = get(homeAddress, "city", "");
  const st = get(homeAddress, "state", "");
  const zip = get(homeAddress, "zipCode", "");
  const shippingStreet = get(shippingAddress, "street", "");
  const shippingCity = get(shippingAddress, "city", "");
  const shippingSt = get(shippingAddress, "state", "");
  const shippingZip = get(shippingAddress, "zipCode", "");
  const status = get(member, "data.status", "");
  const memberSignatureOptOut = get(
    member,
    "data.memberSignatureOptOut",
    false
  );
  const secondaryMemberFn = get(member, "data.secondaryMemberFirstName", "");
  const secondaryMemberLn = get(member, "data.secondaryMemberLastName", "");
  const secondaryMemberBday = get(member, "data.secondaryMemberBirthday", "");
  const optOutEmail = get(member, "data.optOutEmail", false);
  const profileObject = {
    contact: {
      Id: contactId,
      FirstName: firstName,
      LastName: lastName,
      Birthdate: birthDate,
      CHW_Membership_Type__c: membershipType,
      Phone: phone,
      Email: email,
      MailingStreet: street,
      MailingCity: city,
      MailingState: st,
      MailingPostalCode: zip,
      OtherStreet: shippingStreet,
      OtherCity: shippingCity,
      OtherState: shippingSt,
      OtherPostalCode: shippingZip,
      CHW_Card_On_File_Optout__c: memberSignatureOptOut,
      CHW_Secondary_Contact_First_Name__c: secondaryMemberFn,
      CHW_Secondary_Contact_Last_Name__c: secondaryMemberLn,
      CHW_Secondary_Contact_Birthday__c: secondaryMemberBday,
      HasOptedOutOfEmail: optOutEmail,
    },
    displayClubType: clubType,
    displayLevel: bottleLevel,
    displayDelivery: deliveryType,
  };

  return {
    email,
    loading,
    potentialEmail,
    loggingIn,
    loggedIn,
    error,
    signinError,
    loginDestination,
    potentialResponse,
    firstName,
    lastName,
    phone,
    memberEmail,
    changingEmailBackend,
    profileObject,
    membershipType,
  };
}

const connectedProfileOlo = withRouter(connect(mapStateToProps)(ProfileOlo));
export { connectedProfileOlo as ProfileOlo };
