import React, { useEffect } from "react";

export function useRenameDocument(name) {
  useEffect(() => {
    document.title = `${name}-Coopers Hawk Wine club`;
  }, []);
}

export function useCarryoutRename(name) {
  useEffect(() => {
    document.title = `${name}-Coopers Hawk Carryout and Delivery`;
  }, []);
}