/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { appConstants } from '../../_constants';
import { parseAddressComponents } from '../../_helpers/google-address';
import mapMarker from '../../assets/images/map-marker.png';
import close from '../../assets/images/icon-circle-close.png';
import { useClickOutside } from './hooks/useClickOutside';
import './styles.css';

const LocationSearch = ({
  segment,
  onSearch,
  onCleared,
  placeholderText,
  value,
  onChange,
}) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: appConstants.googleMapsApiKey,
  });
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState('');
  const [addressComponents, setAddressComponents] = useState('');
  const [placeDetails, setPlaceDetails] = useState();
  const [resultsShowing, setResultsShowing] = useState(false);
  const inputRef = useRef();
  const listRef = useRef();
  const prevLocationRef = useRef();

  useEffect(() => {
    if (placeDetails) {
      if (segment === 2) {
        try {
          const addressComponents = parseAddressComponents(placeDetails);
          setAddressComponents(addressComponents);
        } catch (error) {
          setAddressComponents('');
        }
      }
      let lat = placeDetails.geometry.location.lat();
      let lng = placeDetails.geometry.location.lng();
      prevLocationRef.current = location;
      setLocation({ lat, lng });
      setAddress(placeDetails.formatted_address);
    } else {
      console.log('place-error', placeDetails);
    }
  }, [placeDetails]);

  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    if (
      !isEqual(prevLocation, location) &&
      !isEmpty(location) &&
      !isEmpty(address)
    )
      onSearch({ geo: { location, address }, addressComponents });
    setResultsShowing(false);
  }, [location, address]);

  useEffect(() => {
    setResultsShowing(placePredictions.length > 0);
  }, [placePredictions]);

  const modalRef = useClickOutside(() => {
    setResultsShowing(false);
  });

  const placeSelected = place => {
    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      placeDetails => setPlaceDetails(placeDetails),
    );
  };

  const renderItem = item => (
    <li
      onKeyDown={e => e.key === 'Enter' && placeSelected(item)}
      tabIndex="0"
      role='listitem'
      className="search-row"
      onClick={() => placeSelected(item)}
      key={item.place_id}>
      <img height={20} src={mapMarker} />
      <div className="search-result">{item.description}</div>
    </li>
  );

  return (
    <div className="search-container" ref={modalRef}>
      <span style={{ position: 'relative' }}>
        <input
          ref={inputRef}
          value={value}
          onChange={e => {
            if (e.target.value === '') {
              setLocation({});
              onCleared();
            } else {
              getPlacePredictions({ input: e.target.value });
              onChange(e.target.value);
            }
          }}
          style={{
            height: '40px',
            border: 'none',
            padding: '5px 30px 5px 15px',
            width: '99%',
          }}
          placeholder={placeholderText}
          onKeyDown={event => {
            if (event.key === 'Enter' && window.google && window.google.maps) {
              const currentText = event.target.value;
              const geocoder = new window.google.maps.Geocoder();
              geocoder.geocode({ address: currentText }, (results, status) => {
                if (
                  status == window.google.maps.GeocoderStatus.OK &&
                  results &&
                  results.length
                ) {
                  const place = results[0];
                  const firstLocation = place.geometry.location;
                  const lat = firstLocation.lat();
                  const lng = firstLocation.lng();
                  prevLocationRef.current = location;
                  setLocation({ lat, lng });
                  setAddress(place.formatted_address);
                  return false;
                }
              });
            }
          }}
        />
        {value !== '' && (
          <img
            height={20}
            style={{ position: 'absolute', right: 8, marginTop: 12 }}
            src={close}
            onClick={onCleared}
          />
        )}
      </span>
      {resultsShowing && (
        <ul
          ref={listRef} 
          role='list'
          style={{
            padding:0,
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: 6,
            borderRadius: 5,
            border: '1px solid #ccc',
            zIndex: 99,
          }}>
          {placePredictions.map(item => renderItem(item))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;

LocationSearch.propTypes = {
  onSearch: PropTypes.func,
  onCleared: PropTypes.func,
  placeholderText: PropTypes.string,
};

LocationSearch.defaultProps = {
  onSearch: () => {},
  onCleared: () => {},
  placeholderText: 'Your address',
};
