import React from "react";
import { Modal, Button } from "react-bootstrap";
import parse from "html-react-parser";
import get from 'lodash/get';

import { store } from "../_helpers";
import { alertConstants } from "../_constants";
import { toast, Slide } from "react-toastify";
import "./oloalert.scss";
import "./alert.css";

export const alertActions = {
  success,
  error,
  clear,
  olosuccess,
  oloerror,
  oloLogout,
  memberExclusiveError,
  errorWithX,
  bagNotification,
  warning
};

function success(message, title = "") {
  toast(
    <Modal show onHide={() => toast.dismiss()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title === "" ? "Success!" : title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message && message.toString()}</Modal.Body>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.SUCCESS, message };
}

function error(message, details) {
  toast(
    <Modal show onHide={() => toast.dismiss()} centered>
      <Modal.Header className="alert-error-header" closeButton>
        <Modal.Title className="alert-error-title">Error</Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert-error-body" aria-live='polite'>
        {message && message.toString()}
        {
          details && 
            <>
              {message && <br />} 
              {details}
            </>
        }
      </Modal.Body>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.ERROR, message };
}

function errorWithX(message, details, onDismiss = () => {}) {
  toast(
    <Modal show onHide={() => {
      toast.dismiss();
      onDismiss();
    }} centered>
      <Modal.Header className="alert-error-header-x" closeButton>
        <Modal.Title className="alert-error-title">Error</Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert-error-body" aria-live='polite'>
        {message && message.toString()}
        {
          details && 
            <>
              <br/>
              {details}
            </>
        }
      </Modal.Body>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.ERROR, message };
}

function oloLogout(onLogout) {
  const { basket } = store.getState();
  const products = get(basket, 'data.products', []);

  let message = "Are you sure?";
  if (products.length) {
    message += " Your bag will be cleared.";
  }

  toast(
    <Modal show onHide={() => toast.dismiss()} centered>
      <Modal.Header closeButton bsPrefix="olo-header">
        <Modal.Title bsPrefix="olo-title">Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="olo-body">{message}</Modal.Body>
      <Modal.Footer style={{ backgroundColor: "white" }}>
        <Button
          onClick={onLogout}
          style={{
            width: "100%",
            height: 50,
            fontFamily: "Oswald",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          LOG OUT
        </Button>
      </Modal.Footer>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.LOGOUT };
}

function olosuccess(message, title = "", action = null, actionName = "") {
  toast(
    <Modal show onHide={() => toast.dismiss()} centered>
      <Modal.Header closeButton bsPrefix="olo-header">
        <Modal.Title bsPrefix="olo-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="olo-body">
        {message && parse(message.toString())}
      </Modal.Body>
      {action && (
        <Modal.Footer style={{ backgroundColor: "white" }}>
          <Button
            onClick={action}
            style={{
              width: "100%",
              height: 50,
              fontFamily: "Oswald",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {actionName}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.SUCCESS, message };
}

function oloerror(message) {
  toast(
    <Modal show onHide={() => {toast.dismiss();}} centered>
      <Modal.Header closeButton bsPrefix="olo-header">
        <Modal.Title bsPrefix="olo-title">Error</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="olo-body">
        {message.toString().trim().includes("TRANSACTION") 
        ? 
        <><h2 style={{color:'#222'}}>Timeout Error</h2>
        <br/>
        <p style={{color:'#222'}}>This form has timed out. Please refresh the page, or try again later.</p>
        <br/>
        <button onClick={()=>{window.location.reload()}}  style={{color:'#222'}}>Go back</button> </>
        : message.toString()  }
      </Modal.Body>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function memberExclusiveError(history) {
  toast(
    <Modal show onHide={() => toast.dismiss()} centered>
      <Modal.Header className="alert-error-header" closeButton>
        <Modal.Title className="alert-error-title">
          Join the Cooper's Hawk Wine Club to gain access to this bottle and to save 10% on your carryout order today.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert-error-body">
        Sorry, this menu item is only available for Wine Club Members.

        <a href="https://chwinery.com/wine-club/overview" target="_blank">
          <span
            style={{
              textDecoration: "underline",
              color: "black",
            }}
          >
            {" "} Learn More and join
          </span>
        </a>
        <span> or </span>

        <span
          style={{
            textDecoration: "underline",
            color: "black",
          }}
          onClick={() => history.push("/login-olo?continueAsType=member")}
        >
          {" "}
          Login as a Member.
        </span>

      </Modal.Body>
    </Modal>
  ,{
    containerId: 'alert'
  });
  return { type: alertConstants.MEMBERSHIP_EXCLUSIVE_ERROR };
}

function bagNotification(itemName) {
  toast.success(itemName + ' added to bag!', {
    position: "top-center",     
    transition: Slide,
    autoClose: 3500,
    containerId: 'toastNotification'
  });
  return { type: alertConstants.BAG_NOTIFICATION };
}

function warning(message, customClass="") {
  toast.warn(message, {
    position: "top-center",
    transition: Slide,
    autoClose: 8000,
    containerId: 'toastWarning',
    className: customClass
  });
  return { type: alertConstants.WARNING };
}